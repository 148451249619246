// This is the Features page
// It shows mainly 3 features of the app: the map, the discovery feature and booking feature
// The map feature is the core of the app and allows users to explore different locations around the world. Users can browse through a variety of destinations by swiping through different locations, gaining a deeper understanding of the types of places they are interested in. The map feature also allows users to upload pictures and thoughts, directly tied to a location, and share their personal experiences and insights. Other users can then vote on their preferred images and comments, and the image with the most votes will be featured on the "discovery" tab, and the comment with the highest votes will appear when the card is tapped.
//The discovery feature utilizes machine learning algorithms to suggest places that align with the user's preferences. The feature takes into account the user's past browsing history, the images and comments they have posted, and the preferences of their friends. The feature also allows users to filter the suggested places by budget, availability and other criteria.
//The booking feature allows users to book flights and hotels to their desired destination. The feature also allows users to plan and book virtual and augmented reality activities through the app. It integrates with popular travel booking websites and airlines to provide users with the best available options.

import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import globe from "../assets/globe.png";
import swipe from "../assets/swipe.png";
import book from "../assets/book.png";
import background2 from "../assets/background1.png";

const Container = styled.div`
  display: flex;
  // background-color: #f5f5f5;
  background-image: url(${background2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  // height: 100vh;
  padding: 50px 0px;

  @media (max-width: 768px) {
    width: 100vw;
    min-height: 100vh;
    background-attachment: scroll;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  // height: 100vh;
  // padding: 0px 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  // @media (max-width: 1280px) {
  //   flex-direction: column;
  // }
`;

const Card = styled.div`
  // border: 2px solid black;
  background-color: white;
  opacity: 0.7;
  padding: 20px;
  margin: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  // height: 50vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 100px 0 rgba(0, 0, 0, 0.19);

  // @media (max-width: 768px) {
  //   width: 100vw;
  //   // padding: 20px;
  // }

  // @media (max-width: 1024px) {
  //   width: 100vw;
  // }

  @media (max-width: 1280px) {
    width: 80vw;
  }

`;

const FeatureTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  color: black;
`;

const FeatureDescription = styled.h1`
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: black;
`;

const FeatureImage = styled.img`
  // width: 40%;
  width:90px;
  // aspect-ratio: 1/1;
  // align-self: center;
`;

const Title = styled.h1`
  font-size: 2.2em;
  padding-bottom: 50px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
  font-weight: 400;
  text-align: center;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.75em;
  }
`;

const Features = () => {
  return (
    <Container>
      <Title>
        VISTASNAP IS NOT<br></br>
        JUST ANOTHER TRAVEL BOOKING WEBSITE
      </Title>
      <CardContainer>
        <Card>
          <FeatureImage src={globe} />
          <FeatureTitle>Map</FeatureTitle>
          <FeatureDescription>
          Explore the world at your fingertips with our innovative globe and map browsing feature. Swipe through destinations to gain a comprehensive understanding of your desired travel locations. Plus, share your vacations with the community and discover what others have to say on the map!
          </FeatureDescription>
        </Card>
        <Card>
          <FeatureImage src={swipe} />
          <FeatureTitle>Discovery</FeatureTitle>
          <FeatureDescription>
          Find the perfect destination tailored to your preferences and social circle with our innovative feature. Not only does it suggest places to go, but it also considers who to go with. Effortlessly filter your options by budget, availability, and other criteria to effortlessly plan your next adventure.
          </FeatureDescription>
        </Card>
        <Card>
          <FeatureImage src={book} />
          <FeatureTitle>Booking</FeatureTitle>
          <FeatureDescription>
          Easily plan your trip from start to finish with our seamless booking feature. Find and book the best flights and hotels for your desired destination with just a few clicks. Benefit from integrated partnerships with top travel booking websites and airlines to secure the best available options.
          </FeatureDescription>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default Features;
