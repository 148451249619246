// This is the How it works page

// 1. Browse locations: Browse different destinations around the world using our map feature. You can swipe through different locations to see what they have to offer.
// 2. Share your experiences: Share your personal experiences and recommendations by uploading pictures and comments, directly tied to a location. You can also connect with friends, build a shared preference and plan trips together.
// 3. Discover new places: Our discovery feature uses machine learning algorithms to suggest places that align with your preferences. You can filter the suggested places based on your budget, availability, and other criteria.
// 4. Book your trip: Book your flights and hotels for your desired destination with our integrated booking feature. You can also plan and book virtual and augmented reality activities through our app. With Xplory, you have the tools to make your travel experiences memorable and sustainable.

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import { Button } from "./Button";
// import { FaArrowRight } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  // min-height: 100vh;
  padding:40px;
  padding-bottom:100px;
  background-color: #ededed;
  // padding:
`;

const Heading = styled.h1`
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  color: white;
`;

const Order = styled.ol`
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: black;
  // display: grid;
  // padding:30px;
  list-style-type: none;
`;

const ListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`;

const ListItem = styled.li`
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: black;
`;

const ListItemCard = styled.div`
  border: 2px solid black;
  padding: 50px;
  margin: 20px;
  border-radius: 30px;
  width: 30vw;
  height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ListItemTitle = styled.h1`
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  color: black;
`;

const ListItemDescription = styled.p`
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: black;
`;



const How = () => {
  return (
    <Container>
      <Heading>How it works</Heading>
      {/* <Grid></Grid> */}
      <Order>
        <ListContainer>
        <ListItem>
            <ListItemCard>
                <ListItemTitle>Browse locations</ListItemTitle>
                <ListItemDescription>Browse different destinations around the world using our map feature. You can swipe through different locations to see what they have to offer.</ListItemDescription>
            </ListItemCard>
        </ListItem>
        <ListItem>
            <ListItemCard>
                <ListItemTitle>Share your experiences</ListItemTitle>
                <ListItemDescription>Share your personal experiences and recommendations by uploading pictures and comments, directly tied to a location. You can also connect with friends, build a shared preference and plan trips together.</ListItemDescription>
            </ListItemCard>
        </ListItem>
        <ListItem>
            <ListItemCard>
                <ListItemTitle>Discover new places</ListItemTitle>
                <ListItemDescription>Our discovery feature uses machine learning algorithms to suggest places that align with your preferences. You can filter the suggested places based on your budget, availability, and other criteria.</ListItemDescription>
            </ListItemCard>
        </ListItem>
        <ListItem>
            <ListItemCard>
                <ListItemTitle>Book your trip</ListItemTitle>
                <ListItemDescription>Book your flights and hotels for your desired destination with our integrated booking feature. You can also plan and book virtual and augmented reality activities through our app. With Xplory, you have the tools to make your travel experiences memorable and sustainable.</ListItemDescription>
            </ListItemCard>
        </ListItem>
        </ListContainer>
      </Order>
    </Container>
  );
};

export default How;