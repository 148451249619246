// Description: This is the main component of the application. It is the parent component of all other components.
// This is a landing page for the application
// It is a simple page with a email input field and a button
// When the user clicks on the button, the email is sent to the server and stored under alpha users list
//

import React, { useState } from "react";
import "./App.css";
import axios from "axios";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";
import Features from "./components/Features";
import FAQ from "./components/FAQ";
import How from "./components/How";
import FooterEmailDrop from "./components/FooterEmailDrop";
import Footer from "./components/Footer";

function App() {
  const [scrolling, setScrolling] = useState(false);

  window.onscroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  



  return (
    <Router>
      <Navbar 
      scrolling={scrolling}
      />
      {/* <Route>
        <Route path="/" exact />
      </Route> */}
      <Home />
      <Features />
      {/* <How /> */}
      <FAQ />
      <FooterEmailDrop />
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
