// this is the footer component

import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import logo from "../assets/logowhite.png";
import background3 from '../assets/background3.png';

const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // height: 100vh;
    // background: #fff;
    background-image: url(${background3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-bottom: 1px solid #e6e6e6;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // z-index: 1000;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const LogoText = styled.h1`
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: black;
    margin-left: 20px;
    text-decoration: none;

    @media (max-width: 768px) {
        font-size: 1.5em;
    }
`;

const LogoImg = styled.img`
    width: 50px;
    //   height: 50px;
    margin-left: 20px;
`;

const Footer = () => {
    return (
        <Container>
            <LogoImg src={logo} />
            <LogoText>© 2021, All Rights Reserved</LogoText>
        </Container>
    );
}

export default Footer;
