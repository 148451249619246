// this is the FAQ component that will be rendered on the landing page

import React from "react";
import { useState } from "react";
import styled from "styled-components";
import background5 from "../assets/backgroundwhite.png";

const Container = styled.div`
  display: flex;
  // margin-top: 900px;
  flex-direction: column;
  background-image: url(${background5});
  // background-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-position: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    width: 100vw;
    min-height: 100vh;
    background-attachment: scroll;
  }
`;

const Card = styled.div`
  //   border: 2px solid black;
  padding: 20px;
  opacity: 0.7;
  margin: 20px;
  border-radius: 30px;
  width: 50vw;
  //   height: 20vh;
  background-color: #ededed;

  @media (max-width: 768px) {
    width: 80vw;
  }

  @media (max-width: 1024px) {
    width: 80vw;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: 600;
  text-align: center;
  color: black;
  margin-top: 50px;
`;

const Question = styled.h1`
  font-size: 1.3em;
  font-weight: 400;
  text-align: center;
  color: black;
  //   text-transform: uppercase;
`;

const Answer = styled.h1`
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  color: black;
  text-transform: uppercase;
  display: none;
`;

const ShowAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShowAnswerButton = styled.button`
  border: none;
  // color: white;
  padding: 7px 22px;
  // background-color: #4CAF50;
  background: white;
  // color: white;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  :hover {
    background: linear-gradient(90deg, #adc69c 0%, #bcd1a8 100%);
    color:#4b4632;
  }
`;

const FAQ = () => {
  const [answerShown, setAnswerShown] = React.useState(false);

  const showAnswer = () => {
    setAnswerShown(!answerShown);
  };

  const hideAnswer = () => {
    setAnswerShown(!answerShown);
  };

  // the above architechture must be implemented for each question seperately

  // const [answerShown, setAnswerShown] = React.useState(false);

  // const showAnswer = () => {
  //   setAnswerShown(!answerShown);
  // };
  // const hideAnswer = () => {
  //     setAnswerShown(!answerShown);
  //   };



  return (
    <Container>
      <Title>FAQ</Title>
      <Card>
        <Question>What is the purpose of this website?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          This website is a platform where users can share pictures of and talk
          about their vacations. It provides a way to discover vacation
          destinations from the community. Share your vistas with the world!
        </Answer>
      </Card>
      <Card>
        <Question>Is the website live?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          No. This website is currently under development. It will be live very
          soon. If you are interested in joining the alpha user list, please
          drop your email in text box above and we will send you an invite to
          the alpha.
        </Answer>
      </Card>
      <Card>
        <Question>Can I join the team?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          Yes! We are building a team of developers and creators to help build
          this website. If you are interested in joining the team, please
          contact us at join@vistasnap.io
        </Answer>
      </Card>
      <Card>
        <Question>How does the picture have an attached location?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          The location is attached to the picture by the user. Users are allowed
          to post pictures from anywhere in the world. We trace the location of
          the user when they post a picture. The location is then attached to
          the picture.
        </Answer>
      </Card>
      <Card>
        <Question>How do I make a booking?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          We currently only support bookings for flights. You can make a booking
          by clicking the "Book Now" button on any picture and you will be
          redirected to the booking page for the airport in the picture location
          is in and airport you are currently in.
        </Answer>
      </Card>
      <Card>
        <Question>What problem do we aim to solve at VistaSnap?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          Where should we go this spring break? We aim to solve the problem of
          people not knowing where to go on trip. We aim to solve this problem
          by providing a platform where users can share pictures of their
          vacations. It provides a way to discover vacation destinations from
          the community. Share your vistas with the world!
        </Answer>
      </Card>
      <Card>
        <Question>What should if I'm interested?</Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          If you are interested in joining the alpha user list, please drop your
          email in email box above and we will send you an invite to the alpha
          version of the website.
        </Answer>
      </Card>
      <Card>
        <Question>
          Why are we looking for software engineers and content creators?
        </Question>
        <ShowAnswerContainer>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "none" : "block" }}
          >
            view answers
          </ShowAnswerButton>
          <ShowAnswerButton
            onClick={showAnswer}
            style={{ display: answerShown ? "block" : "none" }}
          >
            hide answers
          </ShowAnswerButton>
        </ShowAnswerContainer>
        <Answer style={{ display: answerShown ? "block" : "none" }}>
          We are looking for software engineers and content creators to help us
          build this website because we want to build a community of builders
          can can react quickly to feedback from our users. We are looking for
          people who are passionate about travel and want to help capture the
          world's vistas.
        </Answer>
      </Card>
    </Container>
  );
};

export default FAQ;
