// This is the navbar component for the LandingPage

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import logo from "../assets/logowhite.png";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;

  width: 100vw;
  height: 10vh;
  background: #121212;
  opacity: 0.75;
  // border-bottom: 1px solid #e6e6e6;
  position: fixed;
  z-index: 1000;

  // @media (max-width: 768px) {
  //   // flex-direction: column;
  // }
`;

const LogoText = styled.h1`
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-left: 20px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    // width: 100%;
  }
`;

const LogoImg = styled.img`
  width: 50px;
  //   height: 50px;
  margin-left: 20px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30vw;
  height: 10vh;
  margin-right: 20px;
  list-style: none;
  text-transform: uppercase;
  font-size: 0.65em;
`;

const Item = styled.li`
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  color: black;
  margin: 0 1em;
  text-decoration: none;
`;

const LeftNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //   width: 30vw;
  height: 10vh;
  margin-left: 20px;
`;

const MiddleNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //   width: 30vw;
  width: 50vh;
  // margin-left: -30vw;
  height: 10vh;
  margin-left: 20px;

  @media (max-width: 768px) {
    // margin-left: 50px;
    width: 40vw;
  }
`;

const RightNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  //   width: 30vw;
  height: 10vh;
  margin-right: 20px;
`;

// button must invert colors on hover

const Button = styled.button`
  background: #fff;
  //   margin-top: 30px;
  font-size: 1em;
  border-radius: 30px;
  border: 1.5px solid black;
  color: black;
  //   margin: 0 1em;
  //   font-size: 1.5em;
  padding: 13px;

  &:hover {
    background: black;
    color: #fff;
  }
`;

const Hamburger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  // background: black;
`;

const Navbar = () =>
  // { user, setUser }
  {
    // this should be hidden when the user is scrolling
    // it should appear when the user is not scrolling
    // it should be fixed to the top of the page
    // the scrolling should be detected by the scroll event listener

    return (
      <Container>
        <LeftNav>
          <LogoImg src={logo} />
          {/* <LogoText>VistaSnap</LogoText> */}
        </LeftNav>

        <MiddleNav>
          {/* <NavLinks> */}
          {/* <List>
          <Item>Home</Item>
          <Item>Features</Item>
          <Item>How it works</Item>
          <Item>FAQ</Item>
        </List> */}
          {/* </NavLinks> */}
          <LogoText>VistaSnap</LogoText>
        </MiddleNav>

        <RightNav>
          {/* <List>   */}
          {/* <Item>Home</Item> */}
          {/* <Item>Features</Item>
          <Item>How it works</Item>
          <Item>FAQ</Item>
        </List> */}
          {/* <Button>Sign Up for Alpha</Button> */}
        </RightNav>

        {/* <NavLinks>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/login">Login</NavLink>
                <NavLink to="/signup">Signup</NavLink>
            </NavLinks> */}
      </Container>
    );
  };

export default Navbar;
