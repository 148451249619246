// this is the home page component
//

import React from "react";
import { useState } from "react";
// import { Form, Input, Button } from 'antd';
import styled from "styled-components";
// import { Link } from "react-router-dom";
import {  db } from "../services/Firebase";
// import logo from "../assets/logo.png";
import homeimg from '../assets/homeimg.png';
import background1 from '../assets/backgroundwhite.png';


const Container = styled.div`
    // background: papayawhip;
  display: flex;
  // flex-direction: column;
  background-image: url(${background1});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  justify-content: center;
  align-items: center;
  width: 100vw;
    height: 100vh;
  //   background: #fff;
  //   border-bottom: 1px solid #e6e6e6;



  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   margin-top: 10vh;
  // }

  @media (max-width: 1024px) {
    flex-direction: column;
    height:auto;
    
  }

  // @media (max-width: 1280px) {
  //   flex-direction: column;
  // }

  @media (max-width: 768px) {
    width: 100vw;
    min-height: 100vh;
    background-attachment: scroll;
  }

`;

const Flexer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  `;

const Tagline = styled.h1`
  font-size: 2.4em;
  font-weight: 500;
  // box-shadow: 0 0 10px #000;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.75em;
    width: 80%;
    margin-top: 10px;
  }

  // @media (max-width: 1024px) {
  //   font-size: 1.5em;
  //   width: 80%;
  //   margin-top: 10px;
  // }

`;

const Button = styled.button`
  background: #000;
  margin-top: 30px;
  padding: 0.5em;
  margin: 0em;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 30px;
  border: 2px solid black;
  color: white;
  //   margin: 0 1em;
  font-size: 1em;
  text-transform: uppercase;
  padding: 15px;
  

  &:hover {
    background: #fff;
    color: #000;
    cursor: pointer;
    border: 2px solid white;





  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    width: 80%;
    margin-top: 30px;
  }

`;

const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  width: 50%;
  // opacity: 0.8;
  font-size: 1.5em;
  color: #000;
  border: 2px solid black;
  // placeholder-font-size: 0.4em;
  // placeholder-color: #fff;
  background: transparent;
  // background-opacity:0.2;
  // border: none;
  margin-top: 10px;
  border-radius: 30px;

  ::placeholder {
    font-size: 0.7em;
    color: grey;}

    @media (max-width: 768px) {
      font-size: 1.5em;
      width: 80%;
      margin-top: 3 0px;


  }
`;

// this is handling submitting the email address to the backend firebase database

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const LandingImage = styled.img`
  width: 35vh;
  // height: 100%;
  // padding: 10px;
  margin: 10px;
  // aspect-ratio: 1/1;
  // object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;
  background: #ededed;

  @media (max-width: 768px) {
    width: 80vw;
    // height: 100vh;
    margin-right: 0px;
    // opacity: 0.95;
  }
`;

const LandingImageContainer = styled.div`
  display: flex;
  margin-top:40px;
  flex-direction: column;
  margin-right: 40px;
  align-items: center;
  justify-content: center;
  padding-bottom:30px;
  width: 30vw;
  // height: 100vh;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    margin-right: 0px;
  }


`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  // margin-top: 30vh;
  // height: 100vh;
  margin-top: 10vh;
  justify-content: center;
  padding:30px;

  // @media (max-width: 768px) {
  //   width: 100vw;
  //   height: 100vh;
  // }

`;




const Home = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("email", email);
    // this is where we will add the email to the database

    db.collection("alphaEmails") // this is the collection name
      .add({
        email: email,
      })
      .then(() => {
        alert("Your email has been submitted!");
      })
      .catch((error) => {
        alert(error.message);
      });

    setEmail("");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <FormContainer>
      <Tagline>
        A new way to discover locations and share travel stories.
      </Tagline>
      
      {/* <Form onSubmit={handleSubmit}> */}
      {/* <Input
          type="email"
          placeholder="Enter email to join our alpha user base"
        //   value={this.state.email}
        //   onChange={this.handleChange}
        /> */}
  <Flexer>
      <Input
        type="email"
        placeholder="Enter email to join our alpha user base"
        value={email}
        onChange={handleChange}
      />

      <Button onClick={handleSubmit}>JOIN EMAIL LIST</Button></Flexer>
      {/* </Form> */}</FormContainer>
      <LandingImageContainer>
        <LandingImage src={homeimg} />
      </LandingImageContainer>

    </Container>
  );
};

export default Home;
