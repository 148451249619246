// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/storage";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";



const config = {
    apiKey: "AIzaSyATKNjTKCJCAk92r7NHjDOJ7DLVf3P4UeE",
    authDomain: "xplory-1bae3.firebaseapp.com",
    databaseURL: "https://xplory-1bae3.firebaseio.com",
    projectId: "xplory-1bae3",
    storageBucket: "xplory-1bae3.appspot.com",
    messagingSenderId: "948088549309",
    appId: "1:948088549309:web:f789d904e3e5a733b1acd4",
    measurementId: "G-K3VD2L4PNH",
};

firebase.initializeApp(config);

const FirebaseAuth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

export { FirebaseAuth, db, storage };