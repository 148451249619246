// this is the component that renders the email on the footer section of the landing page

import React, { useState } from "react";
import styled from "styled-components";
import background3 from "../assets/background1.png";
import { db } from "../services/Firebase";
import logo from "../assets/logowhite.png";

const Container = styled.div`
background-image: url(${background3});
padding-top: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // min-height: 100vh;
    // padding:80px;
    padding-bottom: 80px;

    @media (max-width: 768px) {
        width: 100vw;
        min-height: 100vh;
        background-attachment: scroll;
      }

`;

const Input = styled.input`
    border: 1.5px solid white;
    border-radius: 30px;
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    // width:500px;
    width: 80vw;
    height: 50px;
    background: transparent;
    font-size: 1.5em;
    // background: black;

    opacity:    0.9;
        // text-transform: uppercase;
    text-align: center;
    color: white;

    ::placeholder {
        color: white;
        font-size: 1em;
        // text-transform: uppercase;
        // opacity: 0.9;
    }

    @media (max-width: 768px) {
        width: 80vw;
    }


`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 70vh;
`;


const Button = styled.button`
    background: #fff;
    margin-top: 30px;
    padding: 0.5em;
    margin: 0em;
    opacity: 0.9;
    cursor: pointer;
    border-radius: 30px;
    border: 2px solid white;
    color: black;
    //   margin: 0 1em;
    font-size: 1em;
    text-transform: uppercase;
    padding: 15px;

    &:hover {
        background: #000;
        color: #fff;
        cursor: pointer;
        border: 2px solid black;
    }

    @media (max-width: 768px) {
        width: 80vw;
        margin-top: 30px;
    }

`;

const LogoImg = styled.img`
    width: 50px;
    //   height: 50px;
    // margin-left: 20px;
    margin-top: 75px;

    // @media (max-width: 768px) {


`;

const FooterEmailDrop = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection("alphaEmails").add({
            email: email,
        });
        setEmail("");
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Input
                    type="email"
                    placeholder="Enter your email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <Button type="submit">Submit</Button>
            </Form>

            <LogoImg src={logo} />

        </Container>
    );
};

export default FooterEmailDrop;
